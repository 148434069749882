import { Product } from "@ecommerce-zero/api";
import { create } from "zustand";

interface EditProductModalStore {
  visible: boolean;
  product?: Product;
  categories: string[];
  show(categories: string[], product?: Product): void;
  hide(): void;
}
export const useEditProductModalStore = create<EditProductModalStore>((set) => ({
  visible: false,
  categories: [],
  show: (categories: string[], product?: Product) => {
    console.log("show", product);
    set(() => ({ visible: true, categories, product: product ? product : undefined }));
  },
  hide: () => {
    console.log("hide");
    set(() => ({ visible: false, categories: [], product: undefined }));
  },
}));
