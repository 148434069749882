import { useBrandingStore } from "../../utils/branding.store";
import { Box } from "./Box";

const DEFAULT_IMAGE = "https://cdn.brandfolder.io/R30ALRIS/at/9w35qpfv6vmtnx7bn8s333/GettyImages-1350225561.jpg";
export const Layout = ({ children }: any) => {
  const { branding } = useBrandingStore();

  const image = branding.backgroundImageUrl ? branding.backgroundImageUrl : DEFAULT_IMAGE;

  const backgroundProps = {
    backgroundImage: `url(
        '${image}'
      )`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundAttachment: "fixed",
  };

  const getProps = () => (branding.disableBackgroundImage ? {} : backgroundProps);

  return (
    <Box
      css={{
        maxW: "100%",
        h: "100vh",
        ...getProps(),
      }}
    >
      {children}
    </Box>
  );
};
