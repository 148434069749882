import { Branding } from "@ecommerce-zero/api";
import { create } from "zustand";

export interface BrandingStore {
  branding: Branding;
  loaded: boolean;
  load(): void;
  set(branding: Branding): void;
}

export const useBrandingStore = create<BrandingStore>((set) => ({
  loaded: false,
  branding: {
    id: "dummy",
  },
  load: () => {
    fetch("/api/v1/demos/branding")
      .then((res) => res.json())
      .then((data: Branding) => {
        set(() => ({ branding: data, loaded: true }));
      });
  },
  set: (branding) => {
    set(() => ({ branding }));
  },
}));
