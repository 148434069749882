// eslint-disable-next-line @typescript-eslint/no-unused-vars
import styles from "./app.module.scss";

import { Route, Routes, Outlet } from "react-router-dom";
import { Layout } from "./components/Layout";
import { NavBar } from "./components/navbar";
import { LandingRoute } from "./routes/landing";
import { MobilePage } from "./routes/mobile";
import { SettingsPage } from "./routes/settings";
import { ProductCataloguePage } from "./routes/settings/products";
import { ErrorPage } from "./routes/error";

export function App() {
  return (
    <Routes>
      <Route
        path="/"
        element={
          <Layout>
            <NavBar></NavBar>
            <Outlet></Outlet>
          </Layout>
        }
      >
        <Route path="" element={<LandingRoute />}></Route>
        <Route path="/mobile" element={<MobilePage />}></Route>
        <Route path="/error" element={<ErrorPage />}></Route>
        <Route path="/settings" element={<SettingsPage />}></Route>
        <Route path="/settings/products" element={<ProductCataloguePage />}></Route>
      </Route>
    </Routes>
  );
}

export default App;
