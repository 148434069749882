import { Image } from "@nextui-org/react";
import { useBrandingStore } from "../../utils/branding.store";

const default_logo = "https://elasticbeanstalk-eu-central-1-670720053804.s3.eu-central-1.amazonaws.com/assets/okta.png";

export const OktaLogo = () => {
  const { branding } = useBrandingStore();

  return (
    <Image
      height={75}
      width={75}
      style={{ padding: "10px" }}
      src={branding.logoUrl ? branding.logoUrl : default_logo}
    ></Image>
  );
};
