import { Badge, Button, Col, FormElement, Input, Modal, Radio, Row, Text } from "@nextui-org/react";
import { useEditProductModalStore } from "./edit-product.store";
import { ProductCard } from "./product-card";
import { ChangeEvent, useEffect, useState } from "react";
import { APIError, Product } from "@ecommerce-zero/api";

export interface EditProductProps {
  refresh(): void;
}

interface PreviewProps {
  newProduct: Product | undefined;
  product: Product | undefined;
}
const ProductPreview = (props: PreviewProps) => {
  if (props.product) {
    return (
      <Col>
        <Text h2>Edit Preview</Text>
        <ProductCard editEnabled={false} categories={[]} product={props.product}></ProductCard>
      </Col>
    );
  } else if (props.newProduct && props.newProduct.title.length > 0) {
    return (
      <Col>
        <Text h2>New Product Preview</Text>
        <ProductCard editEnabled={false} categories={[]} product={props.newProduct}></ProductCard>
      </Col>
    );
  } else {
    return (
      <Col>
        <Text>Nothing to show yet.</Text>
      </Col>
    );
  }
};

export const EditProduct = (props: EditProductProps) => {
  const { visible, categories, hide, product } = useEditProductModalStore();

  const [newProduct, setNewProduct] = useState<Product | undefined>(undefined);
  const [error, setError] = useState<string | undefined>();

  const [formField, setFormField] = useState({
    title: "",
    price: 0,
    image: "",
    category: categories[0] || "",
  });
  const [newCategory, setNewCategory] = useState<string>();

  const formFieldHandler = (e: ChangeEvent<FormElement>) => {
    let name = e.target.name;
    let value = e.target.value;
    if (name === "price") {
      setFormField({ ...formField, price: Number(value) });
    } else {
      setFormField({ ...formField, [name]: value });
    }
  };
  const handleCategoryChange = (category: string) => {
    setFormField({ ...formField, category });
  };

  useEffect(() => {
    if (product) {
      setFormField({
        title: product.title,
        price: product.price,
        category: product.category,
        image: product.image,
      });
    }
  }, [product]);

  useEffect(() => {
    if (!product) {
      setNewProduct(formField);
    }
    if (product) {
      product.title = formField.title;
      product.price = formField.price;
      product.category = formField.category;
      product.image = formField.image;
    }
  }, [formField, product]);

  const handleSave = (product: Product | undefined) => {
    let requestPromise;

    if (product && product.id) {
      requestPromise = fetch(`/api/v1/products/${product.id}`, {
        method: "PUT",
        body: JSON.stringify(product),
        headers: {
          "Content-Type": "application/json",
        },
      });
    } else {
      requestPromise = fetch(`/api/v1/products`, {
        method: "POST",
        body: JSON.stringify({ ...formField }),
        headers: {
          "Content-Type": "application/json",
        },
      });
    }

    const handleError = (err: Promise<APIError>) => {
      err
        .then((data: APIError) => {
          console.log(data);
          setError(`Status ${data.statusCode} - ${data.message} (request_id: ${data.request_id})`);
        })
        .catch((err) => {
          console.log(err.message);
        });
    };
    const handleSuccess = (product: Promise<Product>) => {
      product
        .then((data: Product) => {
          console.log(data);
          closeHandler();
        })
        .catch((err) => {
          console.log(err.message);
        });
    };

    requestPromise.then((result) => {
      const res = result.json();
      if (result.status > 399) {
        handleError(res);
      } else {
        handleSuccess(res);
      }
    });
  };

  const handleDelete = () => {
    if (product && product.id) {
      fetch(`/api/v1/products/${product.id}`, {
        method: "DELETE",
      })
        .then(() => {
          closeHandler();
        })
        .catch((err) => {
          console.log(err.message);
        });
    }
  };

  const closeHandler = () => {
    hide();
    setNewProduct(undefined);
    setFormField({
      title: "",
      price: 0,
      image: "",
      category: categories[0] || "",
    });
    props.refresh();
  };
  return (
    <Modal closeButton aria-labelledby="modal-title" open={visible} onClose={closeHandler} width="80%">
      <Modal.Header>
        <Text id="modal-title" h1>
          Create a mew Product
        </Text>
      </Modal.Header>
      <Modal.Body>
        <Row gap={2} style={{ padding: "2em", margin: 0 }}>
          <Col
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "1em",
            }}
          >
            <Text h2>Settings</Text>
            <Text h3>Properties</Text>
            <Input
              label="Title"
              clearable
              name="title"
              bordered
              fullWidth
              color="primary"
              value={formField.title}
              onChange={formFieldHandler}
              placeholder="Title"
            />
            <Input
              name="price"
              label="Price"
              clearable
              bordered
              fullWidth
              color="primary"
              value={formField.price}
              onChange={formFieldHandler}
              placeholder="Price"
            />
            <Input
              label="Image Url"
              clearable
              name="image"
              bordered
              fullWidth
              color="primary"
              value={formField.image}
              onChange={formFieldHandler}
              type="url"
              placeholder="Image URL"
            />
            <Text h3>Category</Text>
            <Radio.Group
              name="category"
              value={formField.category}
              onChange={handleCategoryChange}
              label="Product Category"
            >
              {categories.map((category) => (
                <Radio value={category} size="sm" key={category}>
                  {category.toLocaleUpperCase()}
                </Radio>
              ))}
            </Radio.Group>
            <Input
              label="New Category"
              clearable
              bordered
              fullWidth
              color="primary"
              placeholder="New Category"
              value={newCategory}
              onChange={(e) => {
                setNewCategory(e.target.value);
                handleCategoryChange(e.target.value);
              }}
            />
          </Col>

          <ProductPreview product={product} newProduct={newProduct}></ProductPreview>
        </Row>
      </Modal.Body>

      <Modal.Footer>
        {error !== undefined ? (
          <Row>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "2em",
              }}
            >
              <Badge color="error" variant="flat">
                {error}
              </Badge>
            </Col>
          </Row>
        ) : (
          <></>
        )}
        <Button auto flat ghost color="error" onPress={handleDelete}>
          Delete
        </Button>
        <Button auto onPress={() => handleSave(product)}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
