import { Card, Code, Col, Container, Link, Row, Text } from "@nextui-org/react";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";
import { useSearchParams } from "react-router-dom";

export const ErrorPage = () => {
  const [searchParams] = useSearchParams();
  return (
    <Container style={{ paddingTop: "2em" }}>
      <Card>
        <Card.Header>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Text h1>Error launching Demo: {searchParams.get("demoName")}</Text>
          </div>
        </Card.Header>
        <Card.Body>
          <p>{searchParams.get("error")}</p>
        </Card.Body>
      </Card>
    </Container>
  );
};
