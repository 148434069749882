import { Badge, Button, Card, Col, Container, FormElement, Input, Row, Switch, Text } from "@nextui-org/react";
import { ChangeEvent, useEffect, useState } from "react";
import { Branding } from "@ecommerce-zero/api";
import { useNavigate } from "react-router-dom";
import { useBrandingStore } from "../../../utils/branding.store";
export const SettingsPage = () => {
  const [config, setConfig] = useState<Branding>({ id: "new" });
  const [isDirty, setIsDirty] = useState<boolean>(false);
  const [editDisabled, setEditDisabled] = useState<boolean>(true);

  const [error, setError] = useState<string | undefined>();

  const naviagte = useNavigate();

  const { set } = useBrandingStore();

  const formFieldHandler = (e: ChangeEvent<FormElement>) => {
    let name = e.target.name;
    let value = e.target.value;
    setConfig({ ...config, [name]: value });
    setIsDirty(true);
  };

  useEffect(() => {
    fetch("/api/v1/demos/branding")
      .then((res) => res.json())
      .then((res) => setConfig(res))
      .catch((err) => console.log(err));
  }, []);
  const handleSave = () => {
    const conf = config;
    delete conf.id;
    fetch("/api/v1/demos/branding", {
      method: "PUT",
      body: JSON.stringify(conf),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => res.json())
      .then((res) => {
        console.log(res);
        setError(`Status ${res.statusCode} - ${res.message} (request_id: ${res.request_id})`);
        if (!res.statusCode) {
          setConfig(res);
          set(res);
          setError(undefined);
        }
      })
      .catch((err) => console.log(err));
  };

  const gotoProducts = () => {
    naviagte("/settings/products");
  };

  return (
    <Container style={{ paddingTop: "2em" }}>
      <Card>
        <Card.Header>
          <Row>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "2em",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Text h1>Settings</Text>
                <div
                  style={{
                    display: "flex",
                    width: "100%",

                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <p>Enable editing</p>
                    <Switch checked={!editDisabled} onChange={() => setEditDisabled(!editDisabled)} />
                  </div>
                  <Button onPress={gotoProducts}>Manage Products</Button>
                </div>
              </div>
            </Col>
          </Row>
        </Card.Header>
        <Card.Body>
          <Row>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "2em",
              }}
            >
              <Text h2>General</Text>
              <Input
                name="title"
                label="Title"
                value={config?.title}
                disabled={editDisabled}
                onChange={formFieldHandler}
              />
              <Input
                name="logoUrl"
                onChange={formFieldHandler}
                label="Logo Url"
                value={config?.logoUrl}
                type="url"
                disabled={editDisabled}
              />
              <Input
                name="backgroundImageUrl"
                onChange={formFieldHandler}
                label="Background Image Url"
                value={config?.backgroundImageUrl}
                type="url"
                disabled={editDisabled}
              />
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Text
                  style={{
                    fontSize: "var(--nextui--inputFontSize)",
                  }}
                >
                  Disable Background Image
                </Text>
                <Switch
                  name="disableBackgroundImage"
                  defaultChecked={false}
                  onChange={(e) => {
                    setConfig({ ...config, disableBackgroundImage: e.target.checked });
                    setIsDirty(true);
                  }}
                  checked={config?.disableBackgroundImage}
                  disabled={editDisabled}
                />
              </div>
            </Col>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "2em",
              }}
            >
              <Text h2>Colors</Text>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.375rem",
                }}
              >
                <Text weight="normal" size="$sm">
                  Primary Color
                </Text>
                <div
                  style={{
                    display: "flex",
                    alignItems: "end",
                    gap: "1em",
                  }}
                >
                  <Input
                    style={{ width: "40px" }}
                    disabled={editDisabled}
                    value={config?.primaryColor}
                    name="primaryColor"
                    onChange={formFieldHandler}
                    type="color"
                  />
                  <Input
                    name="primaryColor"
                    onChange={formFieldHandler}
                    value={config?.primaryColor}
                    disabled={editDisabled}
                    width="100%"
                  />
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.375rem",
                }}
              >
                <Text weight="normal" size="$sm">
                  Secondary Color
                </Text>
                <div
                  style={{
                    display: "flex",
                    alignItems: "end",
                    gap: "1em",
                  }}
                >
                  <Input
                    style={{ width: "40px" }}
                    disabled={editDisabled}
                    value={config?.secondaryColor}
                    name="secondaryColor"
                    onChange={formFieldHandler}
                    type="color"
                  />
                  <Input
                    name="secondaryColor"
                    onChange={formFieldHandler}
                    value={config?.secondaryColor}
                    disabled={editDisabled}
                    width="100%"
                  />
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "0.375rem",
                }}
              >
                <Text weight="normal" size="$sm">
                  Background Color
                </Text>
                <div
                  style={{
                    display: "flex",
                    alignItems: "end",
                    gap: "1em",
                  }}
                >
                  <Input
                    style={{ width: "40px" }}
                    disabled={editDisabled}
                    value={config?.backgroundColor}
                    name="backgroundColor"
                    onChange={formFieldHandler}
                    type="color"
                  />
                  <Input
                    name="backgroundColor"
                    onChange={formFieldHandler}
                    value={config?.backgroundColor}
                    disabled={editDisabled}
                    width="100%"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "2em",
              }}
            >
              <Text h2>Landing Page</Text>
              <Input
                onChange={formFieldHandler}
                name="landingPageTagline"
                label="Tagline"
                value={config?.landingPageTagline}
                disabled={editDisabled}
              />
              <Input
                onChange={formFieldHandler}
                name="landingPageImageUrl"
                label="Cover Image Url"
                value={config?.landingPageImageUrl}
                disabled={editDisabled}
                type="url"
              />
            </Col>
            <Col
              style={{
                display: "flex",
                flexDirection: "column",
                padding: "2em",
              }}
            >
              <Text h2>Product Page</Text>
              <Input
                onChange={formFieldHandler}
                name="productsPerRow"
                label="Number of products per row"
                value={config?.productsPerRow}
                type="number"
                disabled={editDisabled}
              />
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          {error !== undefined ? (
            <Row>
              <Col
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "2em",
                }}
              >
                <Badge color="error" variant="flat">
                  {error}
                </Badge>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <Row>
            <Col
              style={{
                display: "flex",
                justifyContent: "flex-end",
                padding: "2em",
              }}
            >
              <Button color="warning" onPress={handleSave} disabled={!isDirty}>
                Save Changes
              </Button>
            </Col>
          </Row>
        </Card.Footer>
      </Card>
    </Container>
  );
};
