import { ListResponse, Product } from "@ecommerce-zero/api";
import { Button, Card, Container, Grid, Text } from "@nextui-org/react";
import { useEffect, useState } from "react";
import { ProductCard } from "./components/product-card";
import { useEditProductModalStore } from "./components/edit-product.store";
import { EditProduct } from "./components/edit-product";

export const ProductCataloguePage = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [categories, setCategories] = useState<string[]>([]);
  const { show } = useEditProductModalStore();

  const load = () => {
    const products = fetch("/api/v1/products")
      .then((response) => response.json())
      .then((data: ListResponse<Product>) => {
        if (data.items) {
          setProducts(data.items);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    const categories = fetch("/api/v1/categories")
      .then((response) => response.json())
      .then((data: ListResponse<string>) => {
        if (data.items) {
          setCategories(data.items);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
    Promise.all([products, categories]).then();
  };

  const selectCategory = (category: string) => {
    fetch(`/api/v1/products?category=${category}`)
      .then((response) => response.json())
      .then((data: ListResponse<Product>) => {
        console.log(data);
        if (data.items) {
          setProducts(data.items);
        }
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  const prune = () => {
    fetch(`/api/v1/products`, {method: "delete"})
      .then(_ => {
        setProducts([]);
        setCategories([]);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };
  useEffect(() => {
    load();
  }, []);

  return (
    <Container style={{ paddingTop: "2em" }}>
      <EditProduct refresh={load} />
      <Card
        style={{
          margin: "1em",
          width: "calc(100% - 2em)",
        }}
      >
        <Card.Header>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Text h1>Product Catalogue</Text>
          </div>
        </Card.Header>
        <Card.Body>
          <Text h4>Available Categories</Text>
          <div style={{ display: "flex", gap: "2em", justifyContent: "space-between", flexWrap: "wrap" }}>
            {categories.map((category) => (
              <Button color="primary" bordered onPress={() => selectCategory(category)}>
                {category}
              </Button>
            ))}
            <Button color={"warning"} onPress={() => show(categories, undefined)}>
              New Product
            </Button>
            <Button color={"error"} onPress={() => prune()}>
              Prune Catalogue
            </Button>
          </div>
        </Card.Body>
      </Card>
      <Grid.Container gap={2} justify="flex-start">
        {products.map((product, index) => (
          <Grid xs={6} sm={3} key={index}>
            <ProductCard product={product} categories={categories} />
          </Grid>
        ))}
      </Grid.Container>
    </Container>
  );
};
