import { Card, Code, Col, Container, Link, Row, Text } from "@nextui-org/react";
import { useEffect, useState } from "react";
import QRCode from "react-qr-code";

export const MobilePage = () => {
  const [config, setConfig] = useState<{
    demoName: string;
    clientId: string;
    domain: string;
  }>();

  const app_url = "https://ecommerce-0.s3.eu-central-1.amazonaws.com/ecommerce0+customer+app";

  useEffect(() => {
    fetch("/api/v1/demos/config/mobile")
      .then((res) => res.json())
      .then((res) => setConfig(res))
      .catch((err) => console.log(err));
  }, []);

  return (
    <Container style={{ paddingTop: "2em" }}>
      <Card>
        <Card.Header>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Text h1>Mobile experience</Text>
            <Code>Android only!</Code>
          </div>
        </Card.Header>
        <Card.Body>
          <p>Setup android emulator (or use your own android device)</p>
          <p>Download the application. (QR Code on the bottom right)</p>
          <p>Install the application in the emulator/real device</p>
          <p>
            Press: <Code>Scan Config</Code>
          </p>
          <p>Scan QR Code on the bottom left</p>
          <p>
            Verify config and press <Code>Apply Config</Code>
          </p>
          <p>Demo the hell out of this thing</p>
          <Row>
            <Col
              style={{
                textAlign: "center",
              }}
            >
              {config !== undefined ? (
                <>
                  <h3>Configuration</h3>
                  <QRCode value={JSON.stringify(config)}></QRCode>
                  <p>Client ID: {config.clientId}</p>
                  <p>Domain: {config.domain}</p>
                </>
              ) : (
                ""
              )}
            </Col>
            <Col
              style={{
                textAlign: "center",
              }}
            >
              <h3>App Download</h3>
              <QRCode value={`${app_url}`}></QRCode>
              <p>
                <Link href={app_url} target="_blank">
                  Download here
                </Link>
              </p>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </Container>
  );
};
