import { StrictMode, useEffect } from "react";
import * as ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { NextUIProvider, createTheme } from "@nextui-org/react";
import App from "./app/app";
import { useBrandingStore } from "./utils/branding.store";

//import './fonts/Aeonik-Air.otf';
//import './fonts/Aeonik-AirItalic.otf';
//import './fonts/Aeonik-Black.otf';
//import './fonts/Aeonik-BlackItalic.otf';
//import './fonts/Aeonik-Bold.otf';
//import './fonts/Aeonik-BoldItalic.otf';
//import './fonts/Aeonik-Light.otf';
//import './fonts/Aeonik-LightItalic.otf';
//import './fonts/Aeonik-Medium.otf';
//import './fonts/Aeonik-MediumItalic.otf';
//import './fonts/Aeonik-Regular.otf';
//import './fonts/Aeonik-RegularItalic.otf';
//import './fonts/Aeonik-Thin.otf';
//import './fonts/Aeonik-ThinItalic.otf';

const theme = createTheme({
  type: "light",
  theme: {
    fonts: {
      sans: "'Aeonik'",
    },
  },
});

const Wrapper = () => {
  const { branding, load, loaded } = useBrandingStore();

  useEffect(() => {
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (branding.primaryColor) {
      document.documentElement.style.setProperty("--nextui-colors-primary", branding.primaryColor);
      document.documentElement.style.setProperty("--nextui-colors-primarySolidHover", branding.primaryColor);
    }
    if (branding.secondaryColor) {
      document.documentElement.style.setProperty("--nextui-colors-secondary", branding.secondaryColor);
      document.documentElement.style.setProperty("--nextui-colors-warning", branding.secondaryColor);
    }
    if (branding.backgroundColor && branding.disableBackgroundImage) {
      document.documentElement.style.setProperty("--nextui-colors-background", branding.backgroundColor);
    }
  }, [branding]);

  if (!loaded) {
    return <h1>Loading</h1>;
  }
  return (
    <StrictMode>
      <NextUIProvider theme={theme}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </NextUIProvider>
    </StrictMode>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<Wrapper />);
