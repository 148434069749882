import { Card, Text } from '@nextui-org/react';
import { useNavigate } from 'react-router-dom';

type AppCardProps = {
  title: string;
  children: React.ReactNode;
  url?: string;
  sameTab?: boolean;
};

export const AppCard = ({ title, children, url, sameTab }: AppCardProps) => {
  const pressable = url !== undefined;

  const naviagte = useNavigate();

  const goTo = () => {
    if (pressable) {
      if (sameTab) {
        naviagte(url);
      } else {
        window.open(url, '_blank');
      }
    }
  };

  return (
    <Card isPressable={pressable} onPress={goTo}>
      <Card.Header>
        <Text h1>{title}</Text>
      </Card.Header>
      <Card.Body>{children}</Card.Body>
    </Card>
  );
};
