import { Navbar, Text } from "@nextui-org/react";
import { Link, useLocation } from "react-router-dom";
import { OktaLogo } from "./OktaLogo";
import { useBrandingStore } from "../../utils/branding.store";

export const NavBar = () => {
  const location = useLocation();
  const shouldHideLoginButton = location.pathname === "/";
  const { branding } = useBrandingStore();
  return (
    <Navbar isBordered variant="sticky" maxWidth="fluid">
      <Navbar.Toggle showIn={"sm"} />
      <Navbar.Brand>
        <Link to="/" style={{ display: "flex" }}>
          <OktaLogo></OktaLogo>
          <Text h1>{branding.title ? branding.title : "eCommerce0"}</Text>
        </Link>
      </Navbar.Brand>
      <Navbar.Content hideIn="sm"></Navbar.Content>
      <Navbar.Content hideIn={"sm"} css={{ d: shouldHideLoginButton ? "none" : "flex" }}></Navbar.Content>

      <Navbar.Collapse></Navbar.Collapse>
    </Navbar>
  );
};
