import { Code, Col, Container, Row, Text } from "@nextui-org/react";
import { AppCard } from "./components/app-card";
import { useEffect, useState } from "react";

export const LandingRoute = () => {
  const [text, setText] = useState(
    <p>
      In order to use this demo please attach and launch the app via <Code>https://demo.okta.com</Code>!
    </p>
  );

  useEffect(() => {
    fetch("/api/v1/demos/whoami")
      .then((response) => response.json())
      .then((data: { demoName?: string; error?: string, message?: string }) => {
        if (!data.error && data.message !== "Unauthorized") {
          setText(
            <p>
              Congratulations, you are all set for <Code>{data.demoName}</Code>. Launch one of the demo apps below!
            </p>
          );
        }
      });
  }, []);

  return (
    <Container>
      <Row gap={2}>
        <Col
          css={{
            paddingTop: "2em",
          }}
        >
          <AppCard title="Get Started">{text}</AppCard>
        </Col>
      </Row>
      <Row gap={2}>
        <Col
          css={{
            paddingTop: "2em",
          }}
        >
          <AppCard title="Store App" url="/store/">
            <p>Use this demo app to showcase comprehensive eCommerce use-cases related to customer identity</p>
            <Text h4>Use-Cases:</Text>
            <ul>
              <li>
                <Text>Passwordless eMail (with Magic Links)</Text>
              </li>
              <li>
                <Text>Sign-Up with username & password</Text>
              </li>
              <li>
                <Text>Account Linking</Text>
              </li>

              <li>
                <Text>Progressive Profiling which enriches the onlineshop experience</Text>
              </li>

              <li>
                <Text>Step-Up MFA</Text>
              </li>
            </ul>
          </AppCard>
        </Col>

        <Col
          css={{
            paddingTop: "2em",
          }}
        >
          <AppCard title="Point of Sale App" url="/pos/">
            <p>
              Use this demo app to showcase a comprehensive in store experience. This application is designed to work in
              conjunction with the mobile app.
            </p>
            <Text h4>Use-Cases:</Text>
            <ul>
              <li>
                <Text>Authenticate with device code</Text>
              </li>
              <li>
                <Text>Continue shopping card experience from the webshop with added value</Text>
              </li>
            </ul>
          </AppCard>
        </Col>
        <Col
          css={{
            paddingTop: "2em",
          }}
        >
          <AppCard title="Mobile App" url="/mobile" sameTab={true}>
            <p>Use this demo app to showcase a mobile experience related to retail.</p>
            <Text h4>Use-Cases:</Text>
            <ul>
              <li>
                <Text>Authenticate with username / password</Text>
              </li>
              <li>
                <Text>Account Linking (with passwordless or google account)</Text>
              </li>
              <li>
                <Text>Authenticate the PoS display</Text>
              </li>
            </ul>
          </AppCard>
        </Col>
      </Row>
      <Row gap={2}>
        <Col
          css={{
            paddingTop: "2em",
          }}
        >
          <AppCard title="Settings" url="/settings" sameTab={true}>
            <p>Configure the look and feel of this demo and add your own products.</p>
          </AppCard>
        </Col>
      </Row>
    </Container>
  );
};
