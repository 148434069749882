import { Product } from "@ecommerce-zero/api";
import { Card, Row, Text } from "@nextui-org/react";
import { useEditProductModalStore } from "./edit-product.store";

export interface ProductCardProps {
  product: Product;
  categories: string[];
  editEnabled?: boolean;
}
export const ProductCard = (props: ProductCardProps) => {
  const { show } = useEditProductModalStore();
  if (props.editEnabled === undefined) {
    props.editEnabled = true;
  }

  return (
    <Card isPressable={props.editEnabled} onPress={() => show(props.categories, props.product)}>
      <Card.Body css={{ p: 0, padding: "1em" }}>
        <Card.Image
          src={props.product?.image || ""}
          objectFit="contain"
          width="100%"
          height={200}
          alt={props.product.title}
        />
      </Card.Body>
      <Card.Footer css={{ justifyItems: "flex-start" }}>
        <Row wrap="wrap" justify="space-between" align="center">
          <Text b>{props.product.title}</Text>
          <Text css={{ color: "$accents7", fontWeight: "$semibold", fontSize: "$sm" }}>{props.product.price}</Text>
        </Row>
      </Card.Footer>
    </Card>
  );
};
